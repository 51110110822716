







































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Col, Row, Image } from "vant";
import mockData from "./mock";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
  },
})
export default class Share extends Mixins(Mixin) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public shareData: any = [];

  mounted(): void {
    this.shareData = mockData;
  }

  shareReturn(): void {
    this.$router.go(-1);
  }
}
